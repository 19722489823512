/* eslint-disable max-len */
import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { MapCtx } from '@/src/contexts';
import Head from '@/src/components/Head';
import Sidebar from '@/src/components/Sidebar';
import Object3D from '@/src/components/SidebarViews/Object3D';

const IndexPage = (props) => {
  const {
    urlParams,
    t
  } = props;
  const {
    print, object
  } = urlParams;
  const { objects3D } = useContext(MapCtx);

  const selectedObject = objects3D && objects3D.find(o => o.id === object);
  console.log(selectedObject);
  const info = selectedObject && selectedObject.info && ((selectedObject.info.title && selectedObject.info.title.ru) || (selectedObject.info.description && selectedObject.info.description.ru));
  
  return (
    <Fragment>
      <Head
        title={t('metaTitle')}
        description={t('metaDescription')}
      />
      {!print && !info && <Sidebar urlParams={urlParams} />}
      {!!info && (
        <Sidebar urlParams={urlParams}>
          <Object3D objectId={object} />
        </Sidebar>
      )}
    </Fragment>
  );
};

IndexPage.getInitialProps = async (ctx) => {
  const {
    query
  } = ctx;
  const {
    pageType
  } = query;
  const page = pageType;
  return {
    page,
  };
};

IndexPage.defaultProps = {};

IndexPage.propTypes = {
  urlParams: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default IndexPage;

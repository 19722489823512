/* eslint-disable max-len */
import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ImgsViewer from 'react-images-viewer';
import { withTranslation } from 'react-i18next';
import { addQueryStringToUrl } from '@/src/helpers';
import { MapCtx } from '@/src/contexts';
import SidebarHeader from '@/src/components/Sidebar/Header';

const Wrap = styled.div`
  padding: 8px 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 1005;
  background: #f5f5f5;
`;

const ContetntWrap = styled.div`
  overflow-y: auto;
  border-radius: 10px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(94, 94, 94, 0.21);
`;

const Header = styled(SidebarHeader)`
  height: 50px;
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.accent};
`;

const CloseButton = styled.button`
  flex: 0 1 auto;
  padding: 0;
`;

const CloseIcon = styled.span`
  font-size: 2.4rem;
  color: #fff;
`;

const Tabs = styled.div`
  padding: 17px 20px 0;
  display: flex;
  justify-content: flex-end;
  background: #fff;
`;

const Tab = styled.button`
  text-transform: capitalize;
  padding: 7px 20px;
  width: 33.33%;
  margin: 0 5px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: -0.43px;
  line-height: 16px;
  text-align: center;
  border-radius: 21px;
  background-color: #f7f7f7;
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.accent};
    color: #fff;
  }
  ${({ theme }) => theme.onSM(`
    &:hover {
      background-color: #f7f7f7;
      color: rgba(0, 0, 0, 0.87);
    }
  `)}
`;

const TabContent = styled.div`
  position: relative;
  display: inline;
  text-transform: uppercase;
`;

const Title = styled.p`
  flex: 1 1 auto;
  color: #fff;
  font-size: 1.6rem;
  line-height: 19px;
  margin: 0 20px 0 0;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
`;

const Content = styled.div`
  padding: 20px;
  background-color: #fff;
  & figure {
    margin:0;
    max-width: 100%;
  }
  & img {
    max-width: 100%;
    margin: 0 0 10px;
    cursor: pointer;
  }
  & p {
    font-size: 15px;
    line-height: 17px;
    margin-top:0;
  }
`;

const Object3D = (props) => {
  const { objects3D, MapGL } = useContext(MapCtx);
  const [currImg, setCurImg] = useState(0);
  const [stateLang, setStateLang] = useState('ru');
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const { objectId } = props;
  // const lang = i18n.language;

  const selectedObject = objects3D.find(o => o.id === objectId);

  const gotoPrevious = () => {
    const prevImg = currImg - 1;
    setCurImg(prevImg);
  };
  
  const gotoNext = () => {
    const nextImg = currImg + 1;
    // if (nextImg)
    setCurImg(nextImg);
  };

  const closeViewer = () => {
    setViewerIsOpen(false);
  };

  const findImages = (str) => {
    const regex = /\b(https?:\/\/\S+(?:png|jpe?g|gif))\b/igm;
    const result = [];
    if (str) {
      let m = '';
      // eslint-disable-next-line no-cond-assign
      while (m = regex.exec(str)) {
        result.push({ src: m[1] });
      }
    }
    return result;
  };

  const closeObject = () => {
    addQueryStringToUrl({ object: null }, false, true);
  };
  
  const imageClick = i => () => {
    setViewerIsOpen(true);
    setCurImg(i);
  };

  useEffect(() => {
    setTimeout(() => {
      MapGL.flyTo({
        center: [selectedObject.lng, selectedObject.lat],
        zoom: 18,
        pitch: 60
      });
    }, 500);
  }, [MapGL.mock]);
  
  useEffect(() => {
    const imagesSel = document.querySelectorAll('.object-3d-content img');
    if (imagesSel && imagesSel.length) {
      imagesSel.forEach((img, i) => {
        img.removeEventListener('click', imageClick(i));
        img.addEventListener('click', imageClick(i));
      });
    }
  });

  const info = selectedObject && selectedObject.info && ((selectedObject.info.title && selectedObject.info.title.ru) || (selectedObject.info.description && selectedObject.info.description.ru));
  if (!info) return null;

  const infoDescription = info && selectedObject.info.description && selectedObject.info.description[stateLang];
  const infoTitle = info && selectedObject.info.title && selectedObject.info.title[stateLang];
  const imagesSrc = findImages(infoDescription);
  // console.log(selectedObject);
  const alterLang = stateLang === 'ru' ? 'ro' : 'ru';
  return (
    <Wrap>
      <ContetntWrap>
        <Header>
          <Title>{infoTitle || ''}</Title>
          <CloseButton onClick={closeObject}>
            <CloseIcon className='icon-close' />
          </CloseButton>
        </Header>
        <Tabs>
          <Tab
            className={stateLang === 'ru' ? 'active' : ''}
            onClick={() => setStateLang(alterLang)}
          >
            <TabContent>{alterLang}</TabContent>
          </Tab>
        </Tabs>
        <Content
          className='object-3d-content'
          dangerouslySetInnerHTML={{ __html: infoDescription }}
        />          
      </ContetntWrap>
      <ImgsViewer
        imgs={imagesSrc}
        backdropCloseable
        currImg={currImg}
        isOpen={viewerIsOpen}
        onClickPrev={gotoPrevious}
        onClickNext={gotoNext}
        onClose={closeViewer}
      />
    </Wrap>
  );
};

Object3D.propTypes = {
  i18n: PropTypes.object.isRequired,
  objectId: PropTypes.string.isRequired,
};

export default withTranslation()(Object3D);
